import $ from 'jquery';

  
import './../../scss/blocks/block-hpabout.scss';
 
import Swiper, {Navigation, Autoplay} from 'swiper';


import 'swiper/swiper.scss'; 
import 'swiper/modules/navigation/navigation.scss'; 


$(document).ready(function() {
 
  /* var swiper = new Swiper(".hpabout__swiper", {
    //cssMode: true,
    
    slidesPerView: 1,
    pagination: {
      el: ".swiper-pagination",
      //type: "fraction",
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    // mousewheel: true,
    //keyboard: true,
  }); */


  var slides = document.getElementsByClassName("hpabout__swiper--wrapper");

  for (var i = 0; i < slides.length; i++) {
    //Distribute(slides.item(i));
    var bannerswiper = new Swiper(slides.item(i).getElementsByClassName('hpabout__swiper').item(0), {
      modules: [Navigation],
      spaceBetween: 10,
      loop: false,
      slidesPerView: 1,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true, 
        //type: "fraction",
      },
      navigation: {
        nextEl: slides.item(i).getElementsByClassName('hpabout__swiper-button-next').item(0),
        prevEl: slides.item(i).getElementsByClassName('hpabout__swiper-button-prev').item(0),
      },
    });  

    var pagination = $('.hpabout__pagination');
    var paginationWrapper = $('.hpabout__pagination--wrapper')
    var paginationItems = pagination.find('.hpabout__pagination--item');
    var activeButtonIndex = 0;
    var activeButton;

    bannerswiper.on('slideChange', function () {
      console.log('Slide changed to: ' + bannerswiper.activeIndex);
      activeButtonIndex = bannerswiper.activeIndex;
      activeButton = paginationItems.eq(activeButtonIndex);
      updatePaginationPosition();
      $('.hpabout__pagination--item').removeClass('active')
      activeButton.addClass('active');
    
      // Zastaviť autoplay, ak sme na poslednom slide
      if (bannerswiper.autoplay && bannerswiper.isEnd) {
        bannerswiper.autoplay.stop();
      } else {
        // Spustiť autoplay, ak sme niekde inde než na poslednom slide
        if (bannerswiper.autoplay) {
          bannerswiper.autoplay.start();
        }
      }
    });

    $('.hpabout__pagination--item').on('click', function () { 
      var swipValue = $(this).data('swip');
      
      // Prepne na slide podľa získanej hodnoty
      if (swipValue !== undefined) {
        bannerswiper.slideTo(swipValue ); // -1 pre index slide (zvyčajne začína od 0)
      }
    });  
 
  }
 
  function updatePaginationPosition() {
    var paginationWidth = pagination.width();
    var activeButtonLeft = activeButton.position().left;
    var activeButtonWidth = activeButton.outerWidth(true);
    var offset;

    if (bannerswiper.isEnd) {
      // offset = paginationWidth - paginationItems.last().outerWidth(true);
    } else {
      offset = activeButtonLeft - paginationWidth / 2 + activeButtonWidth *2 
    }
    
    if (offset < 0) {
      offset = 0;
    }
    
    // pagination.css('transform', 'translateX(' + -offset + 'px)');

    var scrollLeftValue ;

    // Nastavte hodnotu scrollLeft
    if(activeButtonIndex == 0 || activeButtonIndex == 1) {
      paginationWrapper[0].scrollLeft = 0;
    } else {
       scrollLeftValue = activeButtonWidth * activeButtonIndex ;

    }
    paginationWrapper[0].scrollLeft = scrollLeftValue;
  }

})